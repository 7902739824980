.footer {
  width: 100%;
  position: relative;
  background: white;
  border-top: 2px solid green;
}

.footer-container {
  width: 1280px;
  margin: auto;
}

.info {
  width: 100%;
  display: flex;
  justify-content: center;
}

.abtFooter {
  height: 100%;
  width: 100%;
  margin-top: 5px;
  padding-top: 10px;
  display: flex;
  font-size: 12px;
  text-align: center;
  flex-direction: column;
}

.social {
  margin-top: 18px;
}

.location,
.phone,
.fax,
.email {
  display: flex;
  margin: 10px;
}

.location p,
.phone p,
.fax p,
.email p {
  font-size: 12px;
  line-height: 20px;
  text-align: center;
}

.footerBtm {
  background: green;
  color: white;
  text-align: center;
  font-size: 10px;
  padding: 5px;
  margin-top: 10px;
  width: 100%;
}

.infoCol3 {
  position: relative;
  width: 100%;
  padding: 50px;
  margin: 10px 20px;
  text-align: center;
  background: #fff;
  border: 1px solid green;
}

@media screen and (max-width: 1300px) {
  .footer-container {
    width: 100%;
    padding: 20px 15px 20px 15px;

    transition: 0.3s;
  }
}

@media screen and (max-width: 1100px) {
}

@media screen and (max-width: 500px) {
  .location,
  .phone,
  .fax,
  .email {
    display: block;
    margin: 10px;
    justify-content: center;
    text-align: center;
  }

  .location p,
  .phone p,
  .fax p,
  .email p {
    font-size: 10px;
  }
  .abtFooter {
    font-size: 10px;
  }
}

@media screen and (max-width: 350px) {
  .location p,
  .phone p,
  .fax p,
  .email p {
    font-size: 8px;
  }
  .abtFooter {
    font-size: 8px;
  }
  .footerBtm {
    font-size: 8px;
  }
}
