.topTitleaAbout {
  position: absolute;
  width: 100%;
  top: -80px;
  margin: auto;
  font-size: 25px;
  color: white;
  text-align: center;
  justify-content: center;
}
.abt1 {
  margin-top: -20px;
}
.abt1,
.abt2 {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 50px;

  margin-bottom: 80px;
}

.abt3 {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 50px;
}

.abt1 .abt1Col1,
.abt1 .abt1Col2,
.abt2 .abt2Col1,
.abt2 .abt2Col2,
.abt3 .abt3Col1,
.abt3 .abt3Col2 {
  position: relative;
  width: 100%;
  padding: 0;
  text-align: center;
  background: #fff;
}

.abt1 .abt1Col1 h1,
.abt1 .abt1Col2 h1 {
  text-align: center;
  color: green;
  line-height: 50px;
  margin-bottom: 10px;
  border-bottom: 3px solid green;
}

.abt3 .abt3Col1 h1,
.abt3 .abt3Col2 h1 {
  text-align: left;
  color: green;
  line-height: 50px;
  margin-bottom: 10px;
  border-bottom: 3px solid green;
}

.abt1 .abt1Col1 p,
.abt1 .abt1Col2 p,
.abt2 .abt2Col1 p,
.abt2 .abt2Col2 p,
.abt3 .abt3Col1 p,
.abt3 .abt3Col2 p {
  line-height: 20px;
}

.abt2 .abt2Col1 .row1 {
  display: flex;
  position: relative;
  width: 100%;
  background: #fff;
  text-align: left;
  padding: 20px 0 20px 0;
}

.abt3 .abt3Col1 .row1 {
  position: relative;
  width: 100%;
  background: #fff;
  text-align: left;
  padding: 30px 0 25px 0;
}

.abt2 .abt2Col1 .row1 .abtLogo {
  width: 40px;
  height: 100%;
  margin-right: 10px;
}

.abt .abtCol .row1 img {
  width: 100%;
  height: 100%;
}

.abt2 .abt2Col2 img,
.abt3 .abt3Col2 img {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  object-fit: cover;
}

.abt2 .abt2Col2 .abtmask,
.abt3 .abt3Col2 .abtmask {
  width: 100%;
  height: 100%;
  position: relative;
}

@media screen and (max-width: 1300px) {
  .abt1,
  .abt2,
  .abt3 {
    padding: 0 15px 0 15px;
  }
}

@media screen and (max-width: 1150px) {
}

@media screen and (max-width: 1000px) {
  .topTitleaAbout {
    font-size: 20px;
  }
  .abt2,
  .abt3 {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
  }

  .abt2 .abt2Col2 .abtmask,
  .abt3 .abt3Col2 .abtmask {
    height: 350px;
  }

  .abt2 .abt2Col1,
  .abt3 .abt3Col1 {
    grid-row: 2;
  }
  .abt2 .abt2Col2,
  .abt3 .abt3Col2 {
    grid-row: 1;
  }

  .abt2 .abt2Col1 .row1 {
    padding: 10px 0 10px 0;
  }

  .abt3 .abt3Col1 .row1 {
    padding: 10px 0 10px 0;
  }
}

@media screen and (max-width: 600px) {
  .abt1 {
    grid-template-columns: repeat(1, 1fr);
  }

  .abt2 .abt2Col2 .abtmask,
  .abt3 .abt3Col2 .abtmask {
    height: 280px;
  }
}

@media screen and (max-width: 500px) {
}

@media screen and (max-width: 450px) {
  .abt2 .abt2Col2 .abtmask,
  .abt3 .abt3Col2 .abtmask {
    height: 180px;
  }

  .abt1,
  .abt2,
  .abt3,
  .title {
    font-size: 14px;
  }

  .abt2 .abt2Col1 .row1 .abtLogo {
    width: 30px;
  }
}
