.hero {
  height: 100%;
  width: 100%;
}

.mask {
  width: 100%;
  height: 100vh;
  position: relative;
}

.intro-img {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  object-fit: cover;
}

.footerContactBtm {
  background: green;
  color: white;
  text-align: center;
  font-size: 10px;
  padding: 5px;
  width: 100%;
}

.hero .contactTitle {
  background: rgba(255, 255, 255, 0.716);
  border: 2px solid green;
  position: absolute;
  transform: translate(-50%, -10%);
  bottom: 0;
  left: 50%;
  text-align: center;
  padding: 10px;
  min-width: 650px;
}

.hero .contactTitle p {
  font-size: 28px;
  font-weight: 800;
  color: green;
}

.contactTitle .btn1 {
  display: block;
  width: 100%;
  text-transform: none;
}

.contactTitle .btn2 {
  display: block;
  width: 100%;
  text-transform: none;
  color: green;
}

.contactTitle .btn2:hover {
  color: white;
}

.btnContent {
  align-items: center;
  margin: 0;
  display: flex;
  text-align: center;
}

.addlocaCont {
  display: flex;
}
.addlocaCont .addrCont p {
  font-size: 20px;
  text-align: left;
}

@media screen and (max-width: 700px) {
  .hero .contactTitle {
    min-width: 500px;
  }
  .hero .contactTitle p {
    font-size: 20px;
  }
  .addlocaCont .addrCont p {
    font-size: 15px;
  }
  .contactTitle .btn1 {
    font-size: 12px;
  }
  .contactTitle .btn2 {
    font-size: 12px;
  }
}

@media screen and (max-width: 520px) {
  .hero .contactTitle {
    min-width: 100%;
  }
  .addlocaCont {
    display: block;
  }
  .addlocaCont .addrCont p {
    font-size: 12px;
    text-align: center;
  }
  .contactTitle .btn1 {
    font-size: 8px;
  }
  .contactTitle .btn2 {
    font-size: 8px;
  }
}
@media screen and (max-width: 420px) {
  .btnContent {
    display: block;
  }
  .addlocaCont {
    display: block;
  }

  .addlocaCont .addrCont p {
    font-size: 12px;
  }
  .contactTitle .btn1 {
    font-size: 8px;
  }
  .contactTitle .btn2 {
    font-size: 8px;
  }
  .footerContactBtm {
    font-size: 8px;
  }
}
@media screen and (max-width: 300px) {
}
