.header {
  position: fixed;
  width: 100%;
  height: 85px;
  z-index: 10;
  margin: auto;

  border-bottom: 2px solid transparent;
}

.header:hover {
  background: rgba(255, 255, 255, 0.682);
  z-index: 100;
  transition: 0.5s;
  border-bottom: 2px solid green;
}

.header:hover .hamburger .menuBTNopen {
  font-size: 22px;
  color: green;
  transition: 0.5s;
}
.header:hover .nav-menu li a {
  color: green;
  transition: 0.3s;
}

.header:hover .nav-menu li:hover {
  border-bottom: 2px solid green;
  transition: 0.5s;
}

.header-container {
  position: relative;
  width: 1280px;
  margin: auto;
  align-items: center;
  display: flex;
  justify-content: space-between;
  transition: 0.3s;
}
.logo {
  position: relative;
  width: 150px;
  padding: 10px 0;
}

.logo-img {
  width: 100%;
  height: 100%;
}

.header-bg {
  background: rgba(255, 255, 255, 0.682);
  z-index: 100;
  transition: 0.5s;
  border-bottom: 2px solid green;
}

.nav-menu {
  display: flex;
}

.nav-menu li {
  padding: 2px;
  margin-left: 10px;
  border-bottom: 2px solid transparent;
}

.nav-menu li:hover {
  border-bottom: 2px solid white;
  transition: 0.5s;
}

.header-bg .nav-menu li:hover {
  border-bottom: 2px solid green;
  transition: 0.5s;
}

.nav-menu li a {
  font-size: 22px;
  font-weight: 500;
  color: white;
  transition: 0.3s;
}

.header-bg .nav-menu li a {
  color: green;
  transition: 0.3s;
}

.hamburger {
  display: none;
}

.hamburger .menuBTNopen {
  font-size: 22px;
  color: white;
  transition: 0.5s;
}

.hamburger .menuBTNclose {
  font-size: 22px;
  color: green;
  transition: 0.5s;
}

.header-bg .hamburger .menuBTNopen {
  font-size: 22px;
  color: green;
  z-index: 1000;
}

@media screen and (max-width: 1300px) {
  .header-container {
    width: 100%;
    padding: 0 15px 0 15px;

    transition: 0.3s;
  }
}

@media screen and (max-width: 1000px) {
  .header-container {
    width: 100%;
  }

  .nav-menu {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background: rgba(255, 255, 255, 0.895);
    position: absolute;
    top: 0;
    left: -100%;
    z-index: -3;
    transition: 0.3s;
  }

  .nav-menu.active {
    left: 0;
    color: green;
    transition: 0.5s;
  }

  .nav-menu li {
    padding: 2px 0;
    margin-top: 30px;
  }

  .nav-menu li:hover {
    border-bottom: 2px solid green;
    transition: 0.5s;
  }

  .header-bg .nav-menu li:hover {
    border-bottom: 2px solid green;
    transition: 0.5s;
  }
  .nav-menu li a {
    font-size: 22px;
    color: green;
  }

  .hamburger {
    display: block;
  }
}
