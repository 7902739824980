@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;400;500;600;800&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Raleway";
}

body {
  background: white;
}

.content {
  position: relative;
  width: 1280px;
  margin: auto;
  padding: 85px 0;
}

.content2 {
  position: relative;
  width: 1280px;
  margin: auto;
  padding: 40px 0;
}

h1,
h4,
p,
a {
  text-decoration: none;
}

.topTitleAbout {
  position: absolute;
  width: 100%;
  top: -80px;
  margin: auto;
  justify-content: center;
}

.topTitle h1 {
  color: white;
  text-align: center;
  font-size: 50px;
}

.title {
  position: relative;
  color: green;
  width: 100%;
  margin-bottom: 20px;
  text-align: center;
  padding-left: 15px;
  padding-right: 15px;
}

.subtitle {
  position: relative;
  color: green;
  margin-bottom: 20px;
  text-align: center;
  padding-left: 15px;
  padding-right: 15px;
  border-bottom: 3px solid green;
}

ul {
  list-style-type: none;
}

.btn {
  padding: 12px 32px;
  font-size: 1rem;
  text-transform: uppercase;
  background: green;
  color: white;
  border: 1px solid white;
  font-weight: 600;
  cursor: pointer;
}

.btn-light {
  background: transparent;
  color: white;
}

.btn:hover {
  background: rgba(0, 128, 0, 0.5);
  transition: 0.3s;
}

@media screen and (max-width: 1300px) {
  .subtitle {
    margin-left: 15px;
    margin-right: 15px;
  }
}

a {
  color: black;
}
