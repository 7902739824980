.hero {
  height: 100%;
  width: 100%;
}

.mask {
  width: 100%;
  height: 100vh;
  position: relative;
}

.intro-img {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  object-fit: cover;
}

.hero .contentTitle {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 90%;
  left: 50%;
  text-align: center;
  width: 100%;
}

.hero .contentTitle p {
  font-size: 3rem;
  padding: 4rem 0 2rem 0;
  font-weight: 300;
  color: white;
}

.contentTitle .btn {
  margin: 0 0.2rem;
  display: none;
}

.btnHome a {
  color: white;
}

@media screen and (max-width: 1040px) {
  .hero .contentTitle {
    top: 80%;
  }

  .hero .contentTitle p {
    padding: 4rem 0 1rem 0;
    font-weight: 400;
    font-size: 2rem;
  }

  .contentTitle .btn {
    width: 200px;
    display: inline-block;
  }
}

@media screen and (max-width: 500px) {
  .contentTitle .btn {
    width: 150px;
    font-size: 12px;
  }
}

@media screen and (max-width: 300px) {
  .hero .contentTitle p {
    font-size: 1rem;
  }

  .contentTitle .btn {
    width: 130px;
    font-size: 10px;
  }
}
