.topTitleServices {
  position: absolute;
  width: 100%;
  top: -60px;
  margin: auto;
  font-size: 25px;
  color: white;
  text-align: center;
  justify-content: center;
}

.servCol {
  position: relative;
  display: flex;
}

.servCol .servCol1 {
  position: relative;
  width: 30%;
  float: left;
  text-align: center;
}

.servCol .servCol1 img {
  width: 100%;
  height: 100%;
}

.servCol .servCol1 .servTitle {
  position: absolute;
  top: 0;
  background: rgba(0, 128, 0, 0.662);
  width: 100%;
  height: 100%;
}
.servCol .servCol1 .servTitle .titleCol {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: auto;
}
.servCol .servCol1 .servTitle .titleCol h1 {
  color: white;
}

.servCol .servCol2 {
  position: relative;
  width: 70%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2px;
  border: 1px solid green;
}

.servCol .servCol2 .servCol3 {
  justify-content: center;
  position: relative;
  display: block;
  width: 100%;
  padding: 5px;
  margin: auto;
}

.servCol .servCol2 .servCol3 p {
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  color: green;
}

.servCol .servCol2 .servCol3 img {
  width: 100%;
}

@media screen and (max-width: 1300px) {
  .content {
    width: 100%;
    transition: 0.3s;
  }
  .servCol {
    padding: 0 15px 0 15px;
  }
  .servCol .servCol1 {
    width: 20%;
  }
  .servCol .servCol1 .servTitle .titleCol h1 {
    font-size: 25px;
  }
  .servCol .servCol2 {
    width: 80%;
  }
}

@media screen and (max-width: 1150px) {
  .servCol .servCol1 .servTitle .titleCol h1 {
    font-size: 20px;
  }
  .servCol .servCol2 .servCol3 p {
    font-size: 12px;
  }
}

@media screen and (max-width: 1000px) {
  .topTitleServices {
    font-size: 20px;
  }

  .servCol {
    display: block;
  }

  .servCol .servCol1 {
    float: none;
    width: 100%;
    height: 120px;
  }

  .servCol .servCol2 {
    width: 100%;
  }
}

@media screen and (max-width: 810px) {
  .servCol .servCol2 {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 400px) {
  .servCol .servCol2 .servCol3 p {
    font-size: 10px;
  }
}
@media screen and (max-width: 300px) {
  .servCol .servCol2 .servCol3 p {
    font-size: 7px;
  }
}
