/*Services*/
.homeServ {
  position: relative;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
}

.homeServ img {
  width: 100%;
  border: 1px solid green;
}

.homeServ .servCol {
  position: relative;
  width: 100%;
  padding-bottom: 50px;
  margin: 0;
  text-align: center;
  background: #fff;

  border: 1px solid green;
}

.homeServ .servCol:hover {
  background: green;
  color: white;
  transition: 1s;
}

.homeServ .servCol h2 {
  color: green;
  position: absolute;
  width: 100%;
  padding: 10px 0;
  left: 0;
  bottom: 0;
  border-top: 1px solid green;
  transition: 1s;
}

.homeServ .servCol:hover h2 {
  cursor: pointer;
  color: white;
}

.homeServ .servCol p {
  line-height: 30px;
}

@media screen and (max-width: 1300px) {
  .content {
    width: 100%;
    transition: 0.3s;
  }

  .homeServ {
    padding: 20px 15px 20px 15px;
  }
  .homeServ .servCol {
    margin: 0;
  }
}

@media screen and (max-width: 1150px) {
  .homeServ .servCol h2 {
    font-size: 20px;
  }

  .homeServ .servCol p {
    font-size: 15px;
  }
}

@media screen and (max-width: 1000px) {
  .homeServ {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 500px) {
  .homeServ {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media screen and (max-width: 300px) {
}
